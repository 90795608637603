var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "b-card",
          { attrs: { "no-body": "" } },
          [
            _c(
              "b-card-body",
              { staticClass: " pl-1 " },
              [
                _c(
                  "b-tabs",
                  { attrs: { lazy: "" } },
                  [
                    _c("b-tab", { attrs: { title: "Monthly view" } }, [
                      _c("div", { staticClass: "alert-body" }, [_c("Gantt")], 1)
                    ]),
                    _c("b-tab", { attrs: { title: "Annual view" } }, [
                      _c(
                        "div",
                        { staticClass: "alert-body" },
                        [_c("Graph", { ref: "graph" })],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "b-card",
          { attrs: { "no-body": "" } },
          [
            _c(
              "b-card-body",
              { staticClass: " pl-1 " },
              [
                _c(
                  "b-tabs",
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: { lazy: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", {}, [
                                  _c("strong", [_vm._v("Pending requests")]),
                                  _vm.pendingRequestCount > 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-1 translate-middle badge rounded-circle bg-danger",
                                          staticStyle: { "font-size": "0.8em" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.pendingRequestCount)
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c("PendingRequests", {
                              on: {
                                refreshData: function($event) {
                                  return _vm.getSummary()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: { lazy: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", {}, [
                                  _c("strong", [
                                    _vm._v(
                                      "Today's leaves (" +
                                        _vm._s(_vm.todaysLeaveCount) +
                                        ")"
                                    )
                                  ])
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [_c("TodaysLeaves")],
                          1
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: { lazy: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", {}, [
                                  _c("strong", [
                                    _vm._v(
                                      "Scheduled leaves (" +
                                        _vm._s(_vm.scheduledLeaveCount) +
                                        ")"
                                    )
                                  ])
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c("ScheduledLeaves", {
                              on: {
                                refreshData: function($event) {
                                  return _vm.getSummary()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: { lazy: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("span", {}, [
                                  _c("strong", [_vm._v("Locked dates")])
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [_c("PracticeLeaves")],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }