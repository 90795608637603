var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "manage-leave-sidebar-sidebar",
      visible: _vm.isManageLeaveRequestSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-manage-leave-request-sidebar-active", val)
      },
      hidden: function($event) {
        _vm.tabIndex = 0
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "
              },
              [
                _c("h4", { staticClass: "mb-0" }, [
                  _vm._v("Manage Leave Requests")
                ]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c(
              "validation-observer",
              { ref: "refFormObserver" },
              [
                _c(
                  "b-overlay",
                  { attrs: { show: _vm.formLoading, rounded: "sm" } },
                  [
                    _c(
                      "div",
                      { staticClass: "p-2" },
                      [
                        _c(
                          "b-tabs",
                          {
                            attrs: { lazy: "" },
                            model: {
                              value: _vm.tabIndex,
                              callback: function($$v) {
                                _vm.tabIndex = $$v
                              },
                              expression: "tabIndex"
                            }
                          },
                          [
                            _c(
                              "b-tab",
                              { attrs: { title: "General" } },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "mb-1" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12", md: "5" } },
                                      [
                                        _c("h5", [
                                          _vm._v("Leave year start date")
                                        ]),
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Start Date",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            staticClass:
                                                              "input-group-merge"
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-datepicker",
                                                              {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id: "date",
                                                                  "date-format-options": {
                                                                    year:
                                                                      "2-digit",
                                                                    month:
                                                                      "2-digit",
                                                                    day:
                                                                      "2-digit"
                                                                  },
                                                                  "start-weekday":
                                                                    "1",
                                                                  disabled:
                                                                    _vm.isStartDateDisabled,
                                                                  placeholder:
                                                                    "Select",
                                                                  locale:
                                                                    "en-UK",
                                                                  trim: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.date = $$v
                                                                  },
                                                                  expression:
                                                                    "date"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _vm.isStartDateDisabled === true
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "\n                      btn-icon\n                      shadow-sm\n                      bg-white\n                      mt-2\n                      text-primary\n                      shado\n                    ",
                                                attrs: {
                                                  size: "20",
                                                  variant: "outline-white"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeStartDateDisabledState()
                                                  }
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticStyle: {
                                                    color: "#168b9a"
                                                  },
                                                  attrs: {
                                                    icon: "EditIcon",
                                                    size: "18"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mb-1" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { cols: "12", md: "12" }
                                      },
                                      [
                                        _c("h5", [
                                          _vm._v(
                                            "How do you want to track leave?"
                                          )
                                        ]),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { md: "2" } },
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    attrs: { value: true },
                                                    model: {
                                                      value:
                                                        _vm.isTrackLeaveOnDays,
                                                      callback: function($$v) {
                                                        _vm.isTrackLeaveOnDays = $$v
                                                      },
                                                      expression:
                                                        "isTrackLeaveOnDays"
                                                    }
                                                  },
                                                  [_vm._v(" Days ")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { md: "2" } },
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    attrs: { value: false },
                                                    model: {
                                                      value:
                                                        _vm.isTrackLeaveOnDays,
                                                      callback: function($$v) {
                                                        _vm.isTrackLeaveOnDays = $$v
                                                      },
                                                      expression:
                                                        "isTrackLeaveOnDays"
                                                    }
                                                  },
                                                  [_vm._v(" Hours ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  {},
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("h5", [
                                          _vm._v(
                                            "How many leave days can be taken per year?"
                                          )
                                        ]),
                                        _c("label", [
                                          _vm._v(
                                            "You can change this number for each staff member on their Leave History page"
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.isTrackLeaveOnDays
                                  ? _c(
                                      "b-row",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", md: "3" } },
                                          [
                                            _c(
                                              "b-form-radio-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: { type: "number" },
                                                  model: {
                                                    value: _vm.noOfLeaves,
                                                    callback: function($$v) {
                                                      _vm.noOfLeaves = $$v
                                                    },
                                                    expression: "noOfLeaves"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "d-flex",
                                            attrs: { cols: "12", md: "9" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  "margin-top": "10px"
                                                }
                                              },
                                              [_vm._v(" days ")]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ml-2 text-primary",
                                                staticStyle: {
                                                  "margin-top": "10px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " This equates to " +
                                                    _vm._s(
                                                      _vm.noOfLeaves *
                                                        _vm.workingHours
                                                    ) +
                                                    " hours "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "b-row",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", md: "3" } },
                                          [
                                            _c(
                                              "b-form-radio-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: { type: "number" },
                                                  model: {
                                                    value: _vm.noOfLeaves,
                                                    callback: function($$v) {
                                                      _vm.noOfLeaves = $$v
                                                    },
                                                    expression: "noOfLeaves"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "d-flex",
                                            attrs: { cols: "12", md: "9" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  "margin-top": "10px"
                                                }
                                              },
                                              [_vm._v("hours")]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ml-2 text-primary",
                                                staticStyle: {
                                                  "margin-top": "10px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " This equates to " +
                                                    _vm._s(
                                                      _vm.noOfLeaves /
                                                        _vm.workingHours
                                                    ) +
                                                    " days "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                _c(
                                  "b-row",
                                  { staticClass: "mb-1" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { cols: "12", md: "12" }
                                      },
                                      [
                                        _c("h5", [
                                          _vm._v(
                                            "The leave entitlement includes bank holidays?"
                                          )
                                        ]),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { md: "2" } },
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    attrs: { value: true },
                                                    model: {
                                                      value:
                                                        _vm.isIncludeBankHolidays,
                                                      callback: function($$v) {
                                                        _vm.isIncludeBankHolidays = $$v
                                                      },
                                                      expression:
                                                        "isIncludeBankHolidays"
                                                    }
                                                  },
                                                  [_vm._v(" Yes ")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { md: "2" } },
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    attrs: { value: false },
                                                    model: {
                                                      value:
                                                        _vm.isIncludeBankHolidays,
                                                      callback: function($$v) {
                                                        _vm.isIncludeBankHolidays = $$v
                                                      },
                                                      expression:
                                                        "isIncludeBankHolidays"
                                                    }
                                                  },
                                                  [_vm._v(" No ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  {},
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("h5", [
                                          _vm._v("Working hours per day")
                                        ]),
                                        _c("label", [
                                          _vm._v(
                                            "No of hours in a working day (for the NHS this is typically 7.5 hours)"
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  {},
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { cols: "12", md: "4" }
                                      },
                                      [
                                        _c(
                                          "b-form-radio-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                step: "any"
                                              },
                                              model: {
                                                value: _vm.workingHours,
                                                callback: function($$v) {
                                                  _vm.workingHours = $$v
                                                },
                                                expression: "workingHours"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-tab",
                              { attrs: { title: "Maximum leave" } },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "mb-1" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("h5", [
                                          _vm._v(
                                            "Maximum staff on leave per day"
                                          )
                                        ]),
                                        _c("div", { staticClass: "d-flex" }, [
                                          _c("div", { staticClass: "mr-2" }, [
                                            _c("label", [
                                              _vm._v(
                                                "Add maximum leave limit for all roles"
                                              )
                                            ])
                                          ]),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "mr-1" },
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    attrs: { value: true },
                                                    model: {
                                                      value:
                                                        _vm.allowMaxLeaveForAll,
                                                      callback: function($$v) {
                                                        _vm.allowMaxLeaveForAll = $$v
                                                      },
                                                      expression:
                                                        "allowMaxLeaveForAll"
                                                    }
                                                  },
                                                  [_vm._v(" Yes ")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-radio",
                                                  {
                                                    attrs: { value: false },
                                                    model: {
                                                      value:
                                                        _vm.allowMaxLeaveForAll,
                                                      callback: function($$v) {
                                                        _vm.allowMaxLeaveForAll = $$v
                                                      },
                                                      expression:
                                                        "allowMaxLeaveForAll"
                                                    }
                                                  },
                                                  [_vm._v(" No ")]
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.allowMaxLeaveForAll
                                  ? _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", md: "4" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ml-0 set-mb pt-1"
                                              },
                                              [_c("h6", [_vm._v("All roles")])]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "mb-1",
                                            attrs: { cols: "12", md: "2" }
                                          },
                                          [
                                            _c(
                                              "b-form-radio-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    step: "any"
                                                  },
                                                  model: {
                                                    value: _vm.maxLeaveForAll,
                                                    callback: function($$v) {
                                                      _vm.maxLeaveForAll = $$v
                                                    },
                                                    expression: "maxLeaveForAll"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._l(_vm.assignedJobRoles, function(
                                      role,
                                      index
                                    ) {
                                      return _c(
                                        "b-row",
                                        {
                                          key: role.id,
                                          ref: "rowJobRole",
                                          refInFor: true,
                                          attrs: { id: role.id }
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "4" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-0 set-mb pt-1"
                                                },
                                                [
                                                  _c("h6", [
                                                    _vm._v(_vm._s(role.role))
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mb-1",
                                              attrs: { cols: "12", md: "2" }
                                            },
                                            [
                                              _c(
                                                "b-form-radio-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "number",
                                                      step: "any"
                                                    },
                                                    model: {
                                                      value: role.max_count,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          role,
                                                          "max_count",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "role.max_count"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mb-1",
                                              attrs: { cols: "12", md: "2" }
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "ripple",
                                                      rawName: "v-ripple.400",
                                                      value:
                                                        "rgba(113, 102, 240, 0.15)",
                                                      expression:
                                                        "'rgba(113, 102, 240, 0.15)'",
                                                      modifiers: { "400": true }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn-icon rounded-circle",
                                                  attrs: {
                                                    size: "20",
                                                    variant: "outline-white"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeRoleItem(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "MinusCircleIcon",
                                                      size: "17"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                !_vm.allowMaxLeaveForAll
                                  ? _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { md: "3", lg: "7" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "set-mb" },
                                              [
                                                _c(
                                                  "b-dropdown",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "ripple",
                                                        rawName: "v-ripple.400",
                                                        value:
                                                          "rgba(255, 255, 255, 0.15)",
                                                        expression:
                                                          "'rgba(255, 255, 255, 0.15)'",
                                                        modifiers: {
                                                          "400": true
                                                        }
                                                      }
                                                    ],
                                                    staticStyle: {
                                                      "background-color":
                                                        "rgba(255, 225, 225, 0.1)"
                                                    },
                                                    attrs: {
                                                      id: "dropdown-1",
                                                      plain: "",
                                                      drop: "",
                                                      text: "+ Add Role",
                                                      variant:
                                                        " bg-white text-primary"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      { staticClass: "mx-1" },
                                                      [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            staticClass:
                                                              "input-group-merge"
                                                          },
                                                          [
                                                            _c(
                                                              "b-input-group-prepend",
                                                              {
                                                                attrs: {
                                                                  "is-text": ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "feather-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon:
                                                                        "SearchIcon"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id:
                                                                  "icons-search",
                                                                placeholder:
                                                                  "Search"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.filters[
                                                                    "name"
                                                                  ],
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.filters,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "filters['name']"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-1 mr-1 scrollable-menu",
                                                        on: {
                                                          click:
                                                            _vm.repeateAgainJobRole
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.rolesOption,
                                                        function(option) {
                                                          return _c(
                                                            "b-dropdown-item",
                                                            {
                                                              key: option.id,
                                                              attrs: {
                                                                value:
                                                                  option.name
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.selectedRole = option
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    option.role
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "b-tab",
                              { attrs: { title: "Carry forward" } },
                              [
                                _c(
                                  "b-row",
                                  {},
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("h5", [_vm._v("Carry forward")]),
                                        _c("label", [
                                          _vm._v(
                                            "When you get to year end, how many unused annual leave days "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " can staff carry forward into the next year?"
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mb-1" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12", md: "4" } },
                                      [
                                        _c(
                                          "b-form-radio-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: { type: "number" },
                                              model: {
                                                value: _vm.carryForwardCount,
                                                callback: function($$v) {
                                                  _vm.carryForwardCount = $$v
                                                },
                                                expression: "carryForwardCount"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  {},
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-1",
                                        attrs: { md: "9" }
                                      },
                                      [
                                        _c("label", { staticClass: "mr-1" }, [
                                          _vm._v(
                                            "The days will be automatically carried forward on "
                                          )
                                        ]),
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.carryEndDate))
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-tab",
                              { attrs: { title: "Locked dates" } },
                              [
                                _c(
                                  "b-row",
                                  {},
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mt-1",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("h5", [_vm._v("Locked dates")]),
                                        _c("label", [
                                          _vm._v(
                                            "Set dates when leave cannot be taken"
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form",
                                          {
                                            ref: "form",
                                            staticClass: "repeater-form",
                                            on: {
                                              submit: function($event) {
                                                $event.preventDefault()
                                                return _vm.repeateAgain($event)
                                              }
                                            }
                                          },
                                          _vm._l(_vm.leaveItems, function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "b-row",
                                              {
                                                key: item.id,
                                                ref: "row",
                                                refInFor: true,
                                                attrs: { id: item.id }
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3"
                                                    }
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Start Date")
                                                    ]),
                                                    _c("validation-provider", {
                                                      ref: "date",
                                                      refInFor: true,
                                                      attrs: {
                                                        bails: false,
                                                        name: "Date" + index
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "b-input-group",
                                                                      {
                                                                        staticClass:
                                                                          "input-group-merge"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-datepicker",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs: {
                                                                              "date-format-options": {
                                                                                year:
                                                                                  "2-digit",
                                                                                month:
                                                                                  "2-digit",
                                                                                day:
                                                                                  "2-digit"
                                                                              },
                                                                              "start-weekday":
                                                                                "1",
                                                                              placeholder:
                                                                                "Select",
                                                                              locale:
                                                                                "en-UK",
                                                                              trim:
                                                                                ""
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .leaveItems[
                                                                                  index
                                                                                ]
                                                                                  .startTime,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .leaveItems[
                                                                                    index
                                                                                  ],
                                                                                  "startTime",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "leaveItems[index].startTime"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                errors[0]
                                                                  ? _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "The Date field is required"
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3"
                                                    }
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("End Date")
                                                    ]),
                                                    _c("validation-provider", {
                                                      attrs: {
                                                        name: "End Date"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "b-input-group",
                                                                      {
                                                                        staticClass:
                                                                          "input-group-merge"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-datepicker",
                                                                          {
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs: {
                                                                              "date-format-options": {
                                                                                year:
                                                                                  "2-digit",
                                                                                month:
                                                                                  "2-digit",
                                                                                day:
                                                                                  "2-digit"
                                                                              },
                                                                              "start-weekday":
                                                                                "1",
                                                                              min:
                                                                                _vm
                                                                                  .leaveItems[
                                                                                  index
                                                                                ]
                                                                                  .startTime,
                                                                              placeholder:
                                                                                "Select",
                                                                              locale:
                                                                                "en-UK",
                                                                              trim:
                                                                                ""
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .leaveItems[
                                                                                  index
                                                                                ]
                                                                                  .endTime,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .leaveItems[
                                                                                    index
                                                                                  ],
                                                                                  "endTime",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "leaveItems[index].endTime"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "small",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3"
                                                    }
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Reason")
                                                    ]),
                                                    _c("validation-provider", {
                                                      attrs: { name: "Reason" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "b-input-group",
                                                                      {
                                                                        staticClass:
                                                                          "input-group-merge"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs: {
                                                                              type:
                                                                                "text"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .leaveItems[
                                                                                  index
                                                                                ]
                                                                                  .reason,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .leaveItems[
                                                                                    index
                                                                                  ],
                                                                                  "reason",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "leaveItems[index].reason"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "small",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "8px"
                                                    },
                                                    attrs: {
                                                      cols: "12",
                                                      md: "3"
                                                    }
                                                  },
                                                  [
                                                    _vm.leaveItems.length ==
                                                    index + 1
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "ripple",
                                                                rawName:
                                                                  "v-ripple.400",
                                                                value:
                                                                  "rgba(113, 102, 240, 0.15)",
                                                                expression:
                                                                  "'rgba(113, 102, 240, 0.15)'",
                                                                modifiers: {
                                                                  "400": true
                                                                }
                                                              }
                                                            ],
                                                            staticClass:
                                                              "btn-icon rounded-circle mt-1",
                                                            attrs: {
                                                              size: "20",
                                                              variant:
                                                                "outline-white"
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.repeateAgain
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon:
                                                                  "PlusCircleIcon",
                                                                size: "17"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.leaveItems.length > 1
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "ripple",
                                                                rawName:
                                                                  "v-ripple.400",
                                                                value:
                                                                  "rgba(113, 102, 240, 0.15)",
                                                                expression:
                                                                  "'rgba(113, 102, 240, 0.15)'",
                                                                modifiers: {
                                                                  "400": true
                                                                }
                                                              }
                                                            ],
                                                            staticClass:
                                                              "btn-icon rounded-circle mt-1",
                                                            attrs: {
                                                              size: "20",
                                                              variant:
                                                                "outline-white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.removeItem(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon:
                                                                  "MinusCircleIcon",
                                                                size: "17"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex mt-2" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(255, 255, 255, 0.15)",
                                    expression: "'rgba(255, 255, 255, 0.15)'",
                                    modifiers: { "400": true }
                                  }
                                ],
                                attrs: { type: "submit", variant: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.manageLeaveRequest()
                                  }
                                }
                              },
                              [_vm._v(" Save ")]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(186, 191, 199, 0.15)",
                                    expression: "'rgba(186, 191, 199, 0.15)'",
                                    modifiers: { "400": true }
                                  }
                                ],
                                staticClass: "text-primary ml-1 shadow",
                                attrs: {
                                  type: "button",
                                  variant: "outline-white"
                                },
                                on: { click: hide }
                              },
                              [_vm._v(" Cancel ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }