<template>
  <div class="home">
    <div class="float-right">
      <b-row>
        <!-- <b-col >
            <b-form-group>
              <v-select
                  class="bg-white"
                  style="width: 200px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="option1"
                  label= "title1"
                  placeholder="This Year: 2021"
              />
            </b-form-group>
          </b-col> -->

        <b-col>
          <b-button
            size="23"
            variant="outline-white"
            class="btn-icon shadow-sm bg-white text-primary mr-1"
            @click="toggleSideBars()"
          >
            <feather-icon icon="SettingsIcon" size="18" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-tabs lazy>
      <b-tab title="Dashboard">
        <div class="alert-body"><Dashboard ref="graph" /></div>
      </b-tab>

      <b-tab title="Staff leave balance">
        <div class="alert-body"><Staff ref="staffs" /></div>
      </b-tab>
    </b-tabs>

    <ManageLeaveRequestSidebar
      ref="AddLeaveSidebar"
      :is-manage-leave-request-sidebar-active="
        isManageLeaveRequestSidebarActive
      "
      @toggleSideBars="toggleSideBars()"
    />
  </div>
</template>

<script>
import Staff from "./views/staff.vue";
import Dashboard from "./views/dashboard.vue";
import vSelect from "vue-select";
import ManageLeaveRequestSidebar from "./sidebars/manageLeaveRequest.vue";
import PracticeAPI from "@/apis/modules/practice";
import {
  BCard,
  BTabs,
  BTab,
  BAvatar,
  BOverlay,
  BButton,
  BRow,
  BCol,
  BFormGroup,
} from "bootstrap-vue";
export default {
  name: "Home",
  components: {
    ManageLeaveRequestSidebar,
    vSelect,
    Staff,
    Dashboard,
    BCard,
    BTabs,
    BTab,
    BAvatar,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BFormGroup,
  },
  data() {
    return {
      option1: [
        { title1: "2021" },
        { title1: "2022" },
        { title1: "2023" },
        { title1: "2024" },
        { title1: "2025" },
      ],

      isManageLeaveRequestSidebarActive: false,
    };
  },
  watch: {
    async isManageLeaveRequestSidebarActive(val) {
      if (!val) {
        this.$refs["graph"].refreshGraphData();
        this.getPracticeDetails();
        this.$refs.staffs.tableRefresh();
      }
    },
  },
  methods: {
    toggleSideBars() {
      this.isManageLeaveRequestSidebarActive =
        !this.isManageLeaveRequestSidebarActive;
    },
    async getPracticeDetails() {
      try {
        const Response = await PracticeAPI.getPracticeDetails();
        this.$store.state.userWorkingHoursPerDay =
          Response.data.data.working_hours_per_day;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
  },
  mounted() {
    this.getPracticeDetails();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
