var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mp-0" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "d-flex", attrs: { cols: "" } }, [
            _c(
              "div",
              [
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "input-group-merge" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "SearchIcon" }
                            })
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          attrs: { id: "icons-search", placeholder: "Search" },
                          model: {
                            value: _vm.filters["search"],
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "search", $$v)
                            },
                            expression: "filters['search']"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-auto mb-1" },
              [
                _c(
                  "b-button",
                  {
                    staticClass:
                      "btn-icon bg-primary text-white shadow-sm font-weight-bold",
                    attrs: { size: "", variant: "white" },
                    on: {
                      click: function($event) {
                        return _vm.toggleSideBars()
                      }
                    }
                  },
                  [_vm._v(" + Add Leave ")]
                )
              ],
              1
            )
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                [
                  _c("b-table", {
                    ref: "table",
                    staticClass: "mobile_table_css",
                    attrs: {
                      "current-page": _vm.currentPage,
                      "per-page": _vm.pagination.perPage,
                      fields: _vm.fields,
                      filter: _vm.filter,
                      "filter-included-fields": _vm.filterOn,
                      items: _vm.staffList,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "sort-direction": _vm.sortDirection,
                      hover: "",
                      responsive: ""
                    },
                    on: {
                      "update:sortBy": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function($event) {
                        _vm.sortDesc = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(name)",
                        fn: function(data) {
                          return [
                            _c(
                              "b-row",
                              {
                                staticClass: "center text-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.viewHistory(data.item)
                                  }
                                }
                              },
                              [
                                _c("b-avatar", {
                                  staticClass: "badge-minimal",
                                  attrs: {
                                    size: "40",
                                    variant: "light-primary",
                                    src: _vm.getUserImage(data.item.user_id)
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "ml-1 font-weight-bolder pt-1 text-dark"
                                  },
                                  [_vm._v(_vm._s(data.item.name))]
                                ),
                                _c("br")
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(alocated_leave)",
                        fn: function(data) {
                          return [
                            !_vm.staffListData[data.index].isEdit
                              ? _c(
                                  "b-row",
                                  { staticClass: "center" },
                                  [
                                    _c("b-col", { staticClass: "mr-n4" }, [
                                      data.item.track_leave_on_day
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.item.alocated_leave
                                                  ) +
                                                  " days "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.item.alocated_leave
                                                  ) +
                                                  " hrs "
                                              )
                                            ]
                                          )
                                    ]),
                                    _c(
                                      "b-col",
                                      {},
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "btn-icon bg-white text-primary",
                                            attrs: {
                                              size: "23",
                                              variant: "outline-white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editLeaveDays(
                                                  data,
                                                  data.item.user_id,
                                                  data.item.alocated_leave,
                                                  data.item.track_leave_on_day,
                                                  data.item
                                                    .working_hours_per_day,
                                                  data.item.used_days
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "EditIcon",
                                                size: "18"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-0 mr-n4 ml-n1",
                                        attrs: { sm: "8" }
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                size: "lg",
                                                placeholder: "",
                                                type: "number"
                                              },
                                              model: {
                                                value: data.item.alocated_leave,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    data.item,
                                                    "alocated_leave",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.item.alocated_leave"
                                              }
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              { attrs: { "is-text": "" } },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "btn-icon bg-white text-success",
                                                    attrs: {
                                                      size: "sm",
                                                      variant: "outline-white"
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      },
                                                      attrs: {
                                                        icon: "CheckIcon",
                                                        size: "15"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editLeaveDays(
                                                            data,
                                                            data.item.user_id,
                                                            data.item
                                                              .alocated_leave,
                                                            data.item
                                                              .track_leave_on_day,
                                                            data.item
                                                              .working_hours_per_day,
                                                            data.item.used_days
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ]
                        }
                      },
                      {
                        key: "cell(used_days)",
                        fn: function(data) {
                          return [
                            _c(
                              "b-row",
                              {
                                staticClass: "center",
                                on: {
                                  click: function($event) {
                                    return _vm.viewHistory(data.item)
                                  }
                                }
                              },
                              [
                                _c("b-col", { staticClass: "mr-n4" }, [
                                  data.item.track_leave_on_day
                                    ? _c(
                                        "p",
                                        {
                                          staticStyle: { "margin-top": "10px" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                parseFloat(
                                                  data.item.used_days
                                                ).toFixed(1)
                                              ) +
                                              " days "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "p",
                                        {
                                          staticStyle: { "margin-top": "10px" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.item.used_days < 0
                                                  ? (data.item.used_days = 0)
                                                  : ""
                                              ) +
                                              " " +
                                              _vm._s(
                                                parseFloat(
                                                  data.item.used_days *
                                                    data.item
                                                      .working_hours_per_day
                                                ).toFixed(1)
                                              ) +
                                              " hrs "
                                          )
                                        ]
                                      )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(remaining_days)",
                        fn: function(data) {
                          return [
                            _c(
                              "b-row",
                              {
                                staticClass: "center",
                                on: {
                                  click: function($event) {
                                    return _vm.viewHistory(data.item)
                                  }
                                }
                              },
                              [
                                _c("b-col", { staticClass: "mr-n4" }, [
                                  data.item.track_leave_on_day
                                    ? _c(
                                        "p",
                                        {
                                          staticStyle: { "margin-top": "10px" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                parseFloat(
                                                  data.item.remaining_days
                                                ).toFixed(1)
                                              ) +
                                              " days "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "p",
                                        {
                                          staticStyle: { "margin-top": "10px" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.item.remaining_days < 0
                                                  ? (data.item.remaining_days = 0)
                                                  : ""
                                              ) +
                                              " " +
                                              _vm._s(
                                                parseFloat(
                                                  data.item.remaining_days *
                                                    data.item
                                                      .working_hours_per_day
                                                ).toFixed(1)
                                              ) +
                                              " hrs "
                                          )
                                        ]
                                      )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(last_leave)",
                        fn: function(data) {
                          return [
                            _c(
                              "b-row",
                              {
                                staticClass: "center",
                                on: {
                                  click: function($event) {
                                    return _vm.viewHistory(data.item)
                                  }
                                }
                              },
                              [
                                _c("b-col", [
                                  _vm._v(
                                    " " + _vm._s(data.item.last_leave) + " "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-center justify-content-sm-start",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _vm.pagination.totalRows !== 0
                ? _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Showing " +
                        _vm._s(_vm.pagination.from) +
                        " to " +
                        _vm._s(_vm.pagination.to) +
                        " of " +
                        _vm._s(_vm.pagination.totalRows) +
                        " entries"
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-center justify-content-sm-end",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("b-pagination", {
                staticClass: "mb-0 mt-1 mt-sm-0 ",
                attrs: {
                  "per-page": _vm.pagination.perPage,
                  "total-rows": _vm.pagination.totalRows,
                  "first-number": "",
                  "last-number": "",
                  "next-class": "next-item",
                  "prev-class": "prev-item"
                },
                scopedSlots: _vm._u([
                  {
                    key: "prev-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronLeftIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "next-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronRightIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          ),
          _c("AddLeaveSidebar", {
            ref: "AddLeaveSidebar",
            attrs: {
              "is-add-leave-sidebar-active": _vm.isAddLeaveSidebarActive
            },
            on: {
              toggleSideBars: function($event) {
                return _vm.toggleSideBars()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }