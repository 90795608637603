<template>
    <div>
      <div v-if="selectedCell" class="p-1">
        <div>

          <b-row v-if="selectedCell.service">
            <div class="font-weight-bold mr-1 " v-if="selectedCell.Subject !== '.'">{{selectedCell.Subject}}</div>
            <b-badge size="sm" class="mr-1" v-if="selectedCell.is_covid_shift" variant="danger">
              COVID-19
            </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='clinical'" :variant="'light-success'" class="text-capitalize">
                       {{selectedCell.service.type}}
                      </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='non-clinical'" :variant="'light-info'" class="text-capitalize">
                        {{selectedCell.service.type}}
                      </b-badge>

          </b-row>
          <b-row>
            <div class="font-weight-bold mr-1  " v-if="selectedCell.shift_type">{{selectedCell.shift_type.name}}</div>


          </b-row>
          <b-row class="mt-1" v-if="selectedCell">
            <feather-icon icon="CoffeeIcon" class="mr-1" />
            {{momentFormat(selectedCell.StartTime,'ddd DD MMMM YYYY')}}
            <!--                        {{moment().format("YYYY Do MM")}}-->
          </b-row>
          <b-row class="mt-1" v-if="selectedCell">
            <feather-icon icon="ClockIcon" class="mr-1"  />
            {{momentFormat(selectedCell.StartTime,'HH:mm')}} - {{momentFormat(selectedCell.EndTime,'HH:mm')}}
          </b-row>
          <b-row class="mt-1" v-if="selectedCell">
            <feather-icon icon="HelpCircleIcon" class="mr-1"  />
            {{selectedCell.reason}}
          </b-row>
         
        </div>
      </div>
    </div>
</template>

<script>
import MomentMixin from '@/mixins/MomentMixin'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BModal, BAlert,
  BBadge,
  BButtonGroup, VBToggle, VBModal,
} from 'bootstrap-vue'
import SideBar from "@/views/scheduler/sideBar";
import Ripple from 'vue-ripple-directive'

  export default {
    name: 'content',
    mixins: [MomentMixin],
    components: {
      SideBar,
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BCardTitle,
      BListGroup,
      BListGroupItem,
      BCardBody,
      BFormGroup,
      BButton,
      BSidebar,
      BModal,
      BAlert,
      BBadge,
      BButtonGroup,
    },
    directives: {
      Ripple,
    },
    data () {
      return {
        selectedCell:[],
        data: {}
      }
    },
    methods: {

    },
    mounted () {
      this.selectedCell = this.data
    }
  }
</script>

<style scoped>

.applicationIcon{
  background-color: black; color: white ; border-radius: 5px ; padding: 2px
}
.applicationText{
  padding: 2px;
  margin-left: 5px;
  color:  #1B9AAA;
}
.applications{
  cursor: pointer;
}

.userIcon{
  padding-left: 3px;
}


</style>
