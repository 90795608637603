var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "div",
        { staticClass: "float-right" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn-icon shadow-sm bg-white text-primary mr-1",
                      attrs: { size: "23", variant: "outline-white" },
                      on: {
                        click: function($event) {
                          return _vm.toggleSideBars()
                        }
                      }
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "SettingsIcon", size: "18" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-tabs",
        { attrs: { lazy: "" } },
        [
          _c("b-tab", { attrs: { title: "Dashboard" } }, [
            _c(
              "div",
              { staticClass: "alert-body" },
              [_c("Dashboard", { ref: "graph" })],
              1
            )
          ]),
          _c("b-tab", { attrs: { title: "Staff leave balance" } }, [
            _c(
              "div",
              { staticClass: "alert-body" },
              [_c("Staff", { ref: "staffs" })],
              1
            )
          ])
        ],
        1
      ),
      _c("ManageLeaveRequestSidebar", {
        ref: "AddLeaveSidebar",
        attrs: {
          "is-manage-leave-request-sidebar-active":
            _vm.isManageLeaveRequestSidebarActive
        },
        on: {
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }